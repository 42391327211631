import { useCallback, useRef, useState } from 'react';

import ValidationErrorModel, { ValidationMessage } from '@Api/models/ValidationErrorModel';

export const useInputValue = <S>(
  defaultValue: S,
  fields: string | string[]
): [
  S,
  (value: S) => void,
  ValidationMessage[],
  (validationError: ValidationErrorModel | null) => void,
  boolean,
  boolean,
  (newValue: S) => void,
] => {
  const [value, setValue] = useState<S>(defaultValue);
  const [validationError, setValidationError] = useState<ValidationErrorModel | null>(null);
  const initialValue = useRef<S>(defaultValue);

  const fieldNames = Array.isArray(fields) ? fields : [fields];

  const validationErrorMessages: ValidationMessage[] = validationError?.getForFields(fieldNames) ?? [];

  const hasError = validationErrorMessages.length > 0;

  const setInitialValue = useCallback((newValue: S) => {
    initialValue.current = newValue;
    setValue(newValue);
  }, []);

  const isDirty = JSON.stringify(value) !== JSON.stringify(initialValue.current);

  return [value, setValue, validationErrorMessages, setValidationError, hasError, isDirty, setInitialValue];
};
