import React, { useEffect, useState } from 'react';
import { ValidationMessage } from '@Api/models/ValidationErrorModel';
import InputDescription from '@Components/atoms/Form/InputDescription';
import InputLabel from '@Components/atoms/Form/InputLabel';
import { PropsWithClassName } from '@Components/helper';
import { validateEmail } from '@Components/helper/email';
import { TextSize } from '@Helpers/types/text';
import { ErrorText, Input, Root } from './InputEmail.styles';
interface Props extends PropsWithClassName {
  label?: string | null;
  placeholder?: string | null;
  forId: string;
  required?: boolean;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  error?: boolean;
  errorText?: ValidationMessage[];
  disabled?: boolean;
  descriptionText?: string | null;
}
const InputEmail = (props: Props): React.ReactElement => {
  const {
    label,
    placeholder,
    forId,
    required = false,
    onChange,
    onBlur,
    value,
    error = false,
    errorText,
    disabled = false,
    dataTestId,
    descriptionText
  } = props;
  const [emailError, setEmailError] = useState<boolean>(error);
  useEffect(() => {
    setEmailError(error);
  }, [error]);
  const handleBlur = (email: string) => {
    if (!onBlur) {
      return;
    }
    onBlur(email);
  };
  const handleChange = (email: string) => {
    if (validateEmail(email) || email === '') {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    onChange(email);
  };
  const testId = dataTestId ? dataTestId : 'InputEmail';
  return <Root className={props.className} data-testid={testId + '-root'} data-sentry-element="Root" data-sentry-component="InputEmail" data-sentry-source-file="InputEmail.tsx">
      {label && <InputLabel disabled={disabled} error={emailError} required={required} forId={forId}>
          {label}
        </InputLabel>}
      <Input disabled={disabled} error={emailError} onChange={event => handleChange(event.currentTarget.value)} onBlur={event => handleBlur(event.currentTarget.value)} id={forId} value={value} type="email" required={required} placeholder={placeholder ?? undefined} data-testid={testId + '-input'} data-sentry-element="Input" data-sentry-source-file="InputEmail.tsx" />
      {descriptionText && <InputDescription>{descriptionText}</InputDescription>}
      {error && errorText?.map((message: ValidationMessage) => <ErrorText key={message.key} size={TextSize.Small} dataTestId={testId + '-error'}>
            {message.message}
          </ErrorText>)}
    </Root>;
};
export { InputEmail };