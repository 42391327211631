import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { getRegisterSocialAuthBackendUrl, SocialAuthType } from '@Components/helper/url';
import facebook_logo_white from '@Assets/img/logos/facebook_logo_white.svg';
import { ImageContainer, Root } from './FacebookButton.styles';
interface Props extends PropsWithClassName {
  type: 'button' | 'link';
  onClick?: () => void;
  children: React.ReactNode;
}
const FacebookButton = (props: Props): React.ReactElement => {
  const {
    children,
    type,
    onClick
  } = props;
  const {
    user,
    isGlobalContextInitialized
  } = useGlobalContext();
  const [href, setHref] = useState<string>(getRegisterSocialAuthBackendUrl(SocialAuthType.Facebook));
  useEffect(() => {
    if (isGlobalContextInitialized) {
      setHref(getRegisterSocialAuthBackendUrl(SocialAuthType.Facebook, user?.id));
    }
  }, [isGlobalContextInitialized, user]);
  const renderContent = () => {
    return <>
        <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="FacebookButton.tsx">
          <img alt="Facebook Logo" width={35} height={35} src={facebook_logo_white.src} />
        </ImageContainer>
        {children}
      </>;
  };
  if (type === 'link') {
    return <Root as="a" className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'FacebookButton-root'} href={href}>
        {renderContent()}
      </Root>;
  }
  if (type === 'button') {
    return <Root as="button" className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'FacebookButton-root'} onClick={() => {
      if (onClick) {
        onClick();
      }
    }}>
        {renderContent()}
      </Root>;
  }
  return <></>;
};
export { FacebookButton };