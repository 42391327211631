import { useEffect } from 'react';

import useUnsavedChangesStore from '@Store/useUnsavedChangesStore';

const useBlockNavigation = (isDirty: boolean) => {
  const setIsNavigationBlocked = useUnsavedChangesStore((state) => state.setIsNavigationBlocked);

  useEffect(() => {
    setIsNavigationBlocked(isDirty);

    return () => setIsNavigationBlocked(false);
  }, [isDirty, setIsNavigationBlocked]);
};

export default useBlockNavigation;
