import React, { useEffect, useState } from 'react';
import IconDiscord from '@Components/atoms/Icons/IconDiscord';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { getRegisterSocialAuthBackendUrl, SocialAuthType } from '@Components/helper/url';
import { ImageContainer, Root } from './DiscordButton.styles';
interface Props extends PropsWithClassName {
  type: 'button' | 'link';
  onClick?: () => void;
  children: React.ReactNode;
}
const DiscordButton = (props: Props): React.ReactElement => {
  const {
    children,
    type,
    onClick
  } = props;
  const {
    user,
    isGlobalContextInitialized
  } = useGlobalContext();
  const [href, setHref] = useState<string>(getRegisterSocialAuthBackendUrl(SocialAuthType.Discord));
  useEffect(() => {
    if (isGlobalContextInitialized) {
      setHref(getRegisterSocialAuthBackendUrl(SocialAuthType.Discord, user?.id));
    }
  }, [isGlobalContextInitialized, user]);
  const renderContent = () => {
    return <>
        <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="DiscordButton.tsx">
          <IconDiscord data-sentry-element="IconDiscord" data-sentry-source-file="DiscordButton.tsx" />
        </ImageContainer>
        {children}
      </>;
  };
  if (type === 'link') {
    return <Root as="a" className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'DiscordButton-root'} href={href}>
        {renderContent()}
      </Root>;
  }
  if (type === 'button') {
    return <Root as="button" className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'DiscordButton-root'} onClick={() => {
      if (onClick) {
        onClick();
      }
    }}>
        {renderContent()}
      </Root>;
  }
  return <></>;
};
export { DiscordButton };